import React from "react";
import styled from "styled-components";

const Button = ({ text, active }) => {
  return (
    <Main
      style={{
        backgroundColor: active && "#666",
        cursor: active && "not-allowed",
      }}
    >
      {text}
    </Main>
  );
};

export default Button;

const Main = styled.button`
  background-color: #1a73e8;
  color: white;
  padding: 5px 10px;
  border-radius: 0.375rem;
  outline: none;
  border: none;
  box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
  cursor: pointer;

  :hover {
    background-color: #333;
  }
`;
