import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { API } from "../API";
import { startLoading, stopLoading } from "../state/loadingSlice";
import Button from "./ui/Button";
import { showError, showSuccess } from "./ui/ToastStyle";

const AddLayoutConfig = () => {
  const [priority, setPriority] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [layoutType, setLayoutType] = useState("");
  const [supportedLayouts, setSupportedLayouts] = useState([]);
  const [allOffers, setAllOffers] = useState({ blanket: [], product: [] });
  const [items, setItems] = useState([]);
  const [image, setImage] = useState("");

  const dispatch = useDispatch();

  function Submit(e) {
    e.preventDefault();
    dispatch(startLoading());

    let tempItems = [];
    items.map((item) => tempItems.push(JSON.parse(item)));

    API.post("/store/layout-config", {
      snblLayoutId: supportedLayouts.find((item) => layoutType == item.layout)
        .id,
      title,
      description,
      priority,
      items: tempItems,
      image,
    })
      .then((response) => {
        dispatch(stopLoading());
        if (response.data["type"] === 0) {
          showSuccess(response.data["message"]);
          window.location.reload();
        } else {
          showError(response.data["message"]);
        }
      })
      .catch((error) => {
        dispatch(stopLoading());
      });
  }

  function GetSupportedLayout() {
    dispatch(startLoading());
    API.get(`/store/layout-list`)
      .then((response) => {
        dispatch(stopLoading());
        if (response.data["type"] === 0) {
          setSupportedLayouts(response.data["data"]);
        } else {
          showError(response.data["message"]);
        }
      })
      .catch((error) => {
        dispatch(stopLoading());
      });
  }

  function GetAllOffers() {
    dispatch(startLoading());
    API.get(`/offer/all-offers`)
      .then((response) => {
        dispatch(stopLoading());
        if (response.data["type"] === 0) {
          setAllOffers(response.data["data"]);
        } else {
          showError(response.data["message"]);
        }
      })
      .catch((error) => {
        dispatch(stopLoading());
      });
  }

  function AddOrDeleteItems(item, level) {
    const index = items.indexOf(
      JSON.stringify({
        offerRefId: item.snblOfferId,
        offerLevel: level,
      })
    );

    if (index > -1) {
      let tempArray = [...items];
      tempArray.splice(index, 1);
      setItems(tempArray);
    } else {
      setItems([
        ...items,
        JSON.stringify({
          offerRefId: item.snblOfferId,
          offerLevel: level,
        }),
      ]);
    }
  }

  function IfOfferInItems(item, level) {
    return items.indexOf(
      JSON.stringify({ offerRefId: item.snblOfferId, offerLevel: level })
    ) < 0
      ? false
      : true;
  }

  useEffect(() => {
    GetSupportedLayout();
    GetAllOffers();
  }, []);

  return (
    <Main>
      <form className="form" onSubmit={Submit}>
        <div className="inputCtn">
          <p>Title</p>
          <input
            type={"text"}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>

        <div className="inputCtn">
          <p>Description</p>
          <input
            type={"text"}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </div>

        <div className="inputCtn">
          <p>Priority</p>
          <input
            type={"number"}
            value={priority}
            onChange={(e) => setPriority(e.target.value)}
            required
          />
        </div>

        <div className="inputCtn">
          <p>Image URL</p>
          <input
            type={"url"}
            value={image}
            onChange={(e) => setImage(e.target.value)}
            required
          />
        </div>

        <div className="inputCtn">
          <p>Layout</p>
          <select
            value={layoutType}
            onChange={(e) => setLayoutType(e.target.value)}
            required
          >
            <option value="" key="0"></option>
            {supportedLayouts.map((option, index) => (
              <option value={option.layout} key={index}>
                {option.layout}
              </option>
            ))}
          </select>
        </div>

        <div className="inputCtn">
          <p>Offers</p>
          {allOffers.blanket.map((item, index) => (
            <div
              className="layoutCard"
              style={{
                borderColor: IfOfferInItems(item, "BLANKET") && "#1a73e8",
              }}
              key={index}
              onClick={() => AddOrDeleteItems(item, "BLANKET")}
            >
              <h4 className="description">
                <strong>Offer ID : </strong>
                {item.snblOfferId}
              </h4>
              <h4 className="description">
                <strong>Offer level : </strong>
                BLANKET
              </h4>
              <img src={item.image} className="layoutTypeImage" />
            </div>
          ))}
          {allOffers.product.map((item, index) => (
            <div
              className="layoutCard"
              style={{
                borderColor: IfOfferInItems(item, "PRODUCT") && "#1a73e8",
              }}
              key={index}
              onClick={() => AddOrDeleteItems(item, "PRODUCT")}
            >
              <h4 className="description">
                <strong>Offer ID : </strong>
                {item.snblOfferId}
              </h4>
              <h4 className="description">
                <strong>Offer level : </strong>
                PRODUCT
              </h4>
              <img src={item.image} className="layoutTypeImage" />
            </div>
          ))}
        </div>

        <div className="inputCtn">
          <Button text={"Submit"} />
        </div>
      </form>
    </Main>
  );
};

export default AddLayoutConfig;

const Main = styled.div`
  width: 95%;
  margin: auto;
  box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
  border-radius: 15px;
  overflow: hidden;
  padding: 10px;
  margin-bottom: 10px;

  .form {
    width: 100%;
    /* max-width: 500px; */
    padding: 25px;
    border-radius: 7px;
    margin: auto;
  }
  .form h2 {
    text-align: center;
    margin-bottom: 40px;
    color: #333;
  }
  .inputCtn {
    display: grid;
    gap: 4px;
    margin-bottom: 20px;
  }
  .inputCtn p {
    font-size: small;
    margin: 5px;
    font-weight: bold;
  }
  .inputCtn select,
  .inputCtn input {
    padding: 8px 10px;
    outline: #a5a5a5 solid 2px;
    border: none;
    border-radius: 3px;
    font-size: 20px;
  }
  .inputCtn button {
    margin-top: 25px;
    padding: 10px;
    font-size: 20px;
  }
  .layoutCard {
    border: 1px solid #ddd;
    border-radius: 15px;
    padding: 8px;
    position: relative;
    margin-bottom: 10px;
    cursor: pointer;
  }
  .layoutCard:hover {
    transform: scale(1.01);
  }
  .layoutCard h4 {
    color: #666;
    line-height: 1.5;
    font-weight: 500;
    text-align: justify;
    margin: 10px;
  }
  .description {
    margin-bottom: 10px;
  }
  .description > strong {
    margin-right: 10px;
  }
  .layoutImage {
    height: 100px;
    position: absolute;
    right: 40px;
    top: 40px;
    border-radius: 15px;
  }
  .selected {
    border: 1px solid lime;
  }
`;
