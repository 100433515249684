import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../API";
import { startLoading, stopLoading } from "../state/loadingSlice";
import Button from "./ui/Button";
import { showError, showSuccess } from "./ui/ToastStyle";
import AddLayoutConfig from "./AddLayoutConfig";

const SavStore = () => {
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(0);
  const [activeStoreLayout, setActiveStoreLayout] = useState([]);
  const [supportedLayouts, setSupportedLayouts] = useState([]);
  const [allOffers, setAllOffers] = useState({ blanket: [], product: [] });

  const Tabs = [
    "Active Layout",
    "Supported Layouts",
    "Add Layout",
    "My Offers",
  ];

  function SwitchTabs(index) {
    switch (index) {
      case 0:
        GetActiveStoreLayout();
        break;
      case 1:
        GetSupportedLayout();
        break;
      case 3:
        GetAllOffers();
        break;
    }
    setActiveTab(index);
  }

  function GetActiveStoreLayout() {
    dispatch(startLoading());
    API.get(`/store/layout-config`)
      .then((response) => {
        dispatch(stopLoading());
        if (response.data["type"] === 0) {
          setActiveStoreLayout(response.data["data"]);
        } else {
          showError(response.data["message"]);
        }
      })
      .catch((error) => {
        dispatch(stopLoading());
      });
  }

  function GetSupportedLayout() {
    dispatch(startLoading());
    API.get(`/store/layout-list`)
      .then((response) => {
        dispatch(stopLoading());
        if (response.data["type"] === 0) {
          setSupportedLayouts(response.data["data"]);
        } else {
          showError(response.data["message"]);
        }
      })
      .catch((error) => {
        dispatch(stopLoading());
      });
  }

  function GetAllOffers() {
    dispatch(startLoading());
    API.get(`/offer/all-offers`)
      .then((response) => {
        dispatch(stopLoading());
        if (response.data["type"] === 0) {
          setAllOffers(response.data["data"]);
        } else {
          showError(response.data["message"]);
        }
      })
      .catch((error) => {
        dispatch(stopLoading());
      });
  }

  function HideUnhideLayout(item) {
    dispatch(startLoading());
    API.patch(`/store/layout-config`, {
      id: item.id,
      isActive: !item.isActive,
    })
      .then((response) => {
        dispatch(stopLoading());
        if (response.data["type"] === 0) {
          showSuccess(response.data["message"]);
          GetActiveStoreLayout();
        } else {
          showError(response.data["message"]);
        }
      })
      .catch((error) => {
        dispatch(stopLoading());
      });
  }

  useEffect(() => {
    GetActiveStoreLayout();
  }, []);

  return (
    <Main>
      <div className="ctn">
        <div className="filters">
          {Tabs.map((item, index) => (
            <div key={index} onClick={() => SwitchTabs(index)}>
              <Button active={index == activeTab} text={item} />
            </div>
          ))}
        </div>
      </div>

      {/* Tab 1 */}
      {activeTab == 0 && (
        <>
          <div className="ctn">
            {activeStoreLayout.map((item, index) => (
              <div className="layoutCard" key={index}>
                <div className="filters">
                  {/* <div>
                    <Button text={"Edit"} />
                  </div>
                  <div>
                    <Button text={"Delete"} />
                  </div> */}
                  <div onClick={() => HideUnhideLayout(item)}>
                    <Button text={item.isActive ? "Hide" : "Show"} />
                  </div>
                </div>
                <img src={item.image} className="layoutImage" />
                <h4 className="description">
                  <strong>Priority : </strong>
                  {item.priority}
                </h4>
                <h4 className="description">
                  <strong>Title : </strong>
                  {item.title}
                </h4>
                <h4 className="description">
                  <strong>Description : </strong>
                  {item.description}
                </h4>
                <h4 className="description">
                  <strong>Layout type : </strong>
                  {item.layoutName}
                </h4>
                <h4 className="description">
                  <strong>Active : </strong>
                  {item.isActive ? "Yes 🟢" : "No 🔴"}
                </h4>
                <h4 className="description">
                  <strong>Items : </strong>
                </h4>
                <div className="offerItemsRow">
                  {item.items.map((offer, index) => (
                    <div key={index} className="offerItem">
                      <h4 className="description">
                        <strong>Offer Level : </strong>
                        {offer.offerLevel}
                      </h4>
                      <h4 className="description">
                        <strong>Offer ID : </strong>
                        {offer.offerRefId}
                      </h4>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className="ctn">
            <div onClick={() => SwitchTabs(2)}>
              <Button text={"Add Layout"} />
            </div>
          </div>
        </>
      )}

      {/* Tab 2 */}
      {activeTab == 1 && (
        <div className="ctn">
          {supportedLayouts.map((item, index) => (
            <div className="layoutCard" key={index}>
              <h4 className="description">
                <strong>Layout Type : </strong>
                {item.layout}
              </h4>
              <h4 className="description">
                <strong>Maximum items : </strong>
                {item.maxItems}
              </h4>
              <img src={item.image} className="layoutTypeImage" />
            </div>
          ))}
        </div>
      )}

      {/* Tab 3 */}
      {activeTab == 2 && <AddLayoutConfig />}

      {/* Tab 4 */}
      {activeTab == 3 && (
        <>
          <div className="ctn">
            {allOffers.blanket.map((item, index) => (
              <div className="layoutCard" key={index}>
                <h4 className="description">
                  <strong>Offer ID : </strong>
                  {item.snblOfferId}
                </h4>
                <h4 className="description">
                  <strong>Offer level : </strong>
                  BLANKET
                </h4>
                <img src={item.image} className="layoutTypeImage" />
              </div>
            ))}
          </div>
          <div className="ctn">
            {allOffers.product.map((item, index) => (
              <div className="layoutCard" key={index}>
                <h4 className="description">
                  <strong>Offer ID : </strong>
                  {item.snblOfferId}
                </h4>
                <h4 className="description">
                  <strong>Offer level : </strong>
                  PRODUCT
                </h4>
                <img src={item.image} className="layoutTypeImage" />
              </div>
            ))}
          </div>
        </>
      )}
    </Main>
  );
};

export default SavStore;

const Main = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;

  .ctn {
    width: 95%;
    margin: auto;
    box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
    border-radius: 15px;
    overflow: hidden;
    padding: 10px;
    margin-bottom: 10px;
  }
  .filters {
    display: flex;
    gap: 10px;
  }
  .layoutCard {
    border: 1px solid #ddd;
    border-radius: 15px;
    padding: 8px;
    position: relative;
    margin-bottom: 10px;
  }
  .layoutCard h4 {
    color: #666;
    line-height: 1.5;
    font-weight: 500;
    text-align: justify;
    margin: 10px;
  }
  .description {
    margin-bottom: 10px;
  }
  .description > strong {
    margin-right: 10px;
  }
  .layoutImage {
    height: 100px;
    position: absolute;
    right: 40px;
    top: 40px;
    border-radius: 15px;
  }
  .offerItemsRow {
    display: flex;
    gap: 8px;
    overflow-x: auto;
    padding-bottom: 8px;
  }
  .offerItem {
    border: 1px solid #ddd;
    border-radius: 15px;
    padding: 8px;
    width: fit-content;
    min-width: fit-content;
  }
  .offerItemsRow::-webkit-scrollbar {
    height: 12px;
  }
  .offerItemsRow::-webkit-scrollbar-thumb,
  .offerItemsRow::-webkit-scrollbar-track {
    border-radius: 92px;
  }
  .offerItemsRow::-webkit-scrollbar-thumb {
    background: #1a73e8;
  }
  .offerItemsRow::-webkit-scrollbar-track {
    background: #edf2f4;
  }
  .layoutTypeImage {
    height: 76px;
    position: absolute;
    right: 10px;
    top: 10px;
    border-radius: 15px;
  }
`;
