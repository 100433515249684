import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import SavLogo from "../assets/logo.png";
import { hideSideNavbar, setTab } from "../state/sideNavbarSlice";

const SideNavbar = () => {
  const { isSideNavbarOpen, activeTab } = useSelector(
    (state) => state.sideNavbar
  );

  const dispatch = useDispatch();

  const Tabs = ["Dashboard", "Sav Store", "Transactions", "Widget", "Settings"];

  function OpenTab(index) {
    switch (index) {
      case 0:
        dispatch(setTab(0));
        break;
      case 1:
        dispatch(setTab(1));
        break;
      case 2:
        dispatch(setTab(2));
        break;
      case 3:
        dispatch(setTab(3));
        break;
      case 4:
        dispatch(setTab(4));
        break;

      default:
        break;
    }
    dispatch(hideSideNavbar());
    localStorage.setItem('sideNavIndex', index)
  }

  return (
    <Main style={{ display: isSideNavbarOpen ? "flex" : "none" }}>
      <div className="menu">
        <img src={SavLogo} className="savLogo" />
        <div className="tabs">
          {Tabs.map((item, index) => (
            <h4
              style={{
                color: activeTab == index && "black",
                background: activeTab == index && "white",
                boxShadow:
                  activeTab == index && "0 0 2rem 0 rgb(136 152 170 / 15%)",
              }}
              key={index}
              onClick={() => OpenTab(index)}
            >
              {item}
            </h4>
          ))}
        </div>
        <p className="support">
          <a href="mailto:support@sav.money">support@sav.money</a>
        </p>
      </div>
      <div className="empty" onClick={() => dispatch(hideSideNavbar())} />
    </Main>
  );
};

export default SideNavbar;

const Main = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  animation-duration: 0.25s;
  animation-name: fade-in;
  animation-timing-function: ease-in-out;

  .menu {
    width: 260px;
    height: 100%;
    background-color: #f8f9fe;
    text-align: center;
    position: relative;
    animation-duration: 0.25s;
    animation-name: slide-in-left;
    animation-timing-function: ease-out;
  }

  @keyframes slide-in-left {
    0% {
      opacity: 0;
      left: -100%;
    }
    100% {
      opacity: 1;
      left: 0%;
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .empty {
    flex: 1;
    height: 100%;
  }
  .savLogo {
    margin-top: 30px;
    height: 35px;
  }
  .tabs {
    margin: 25px;
    padding-top: 25px;
    border-top: 1px solid #ddd;
  }
  .menu h4 {
    cursor: pointer;
    border-radius: 0.375rem;
    padding: 14px 16px;
    margin-bottom: 25px;
    font-size: 14px;
    font-weight: normal;
    color: #666;
  }
  .support {
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
  }
  .support a {
    color: #666;
    text-decoration: none;
  }
`;
