import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import Button from "./ui/Button";
import { startLoading, stopLoading } from "../state/loadingSlice";
import { showError, showSuccess } from "../components/ui/ToastStyle";
import { API } from "../API";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  function Login(e) {
    e.preventDefault();
    dispatch(startLoading());

    API.post("/user/auth", {
      email,
      password,
    })
      .then((response) => {
        dispatch(stopLoading());
        if (response.data["type"] === 0) {
          localStorage.setItem(
            "authState",
            JSON.stringify(response.data["data"])
          );
          localStorage.setItem("token", response.data["data"].token);
          window.location.reload();
        } else {
          showError(response.data["message"]);
        }
      })
      .catch((error) => {
        dispatch(stopLoading());
      });
  }

  return (
    <Main>
      <form className="form" onSubmit={Login}>
        <h2>Merchant Login</h2>
        <div className="inputCtn">
          <p>Email</p>
          <input
            type={"email"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>

        <div className="inputCtn">
          <p>Password</p>
          <input
            type={"password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>

        <div className="inputCtn">
          <Button text={"Login"} />
        </div>
      </form>
    </Main>
  );
};

export default Login;

const Main = styled.div`
  min-height: calc(100vh - 100px);
  display: grid;
  place-items: center;

  .form {
    box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
    width: 100%;
    max-width: 500px;
    padding: 25px;
    border-radius: 7px;
    margin: auto;
  }
  .form h2 {
    text-align: center;
    margin-bottom: 40px;
    color: #333;
  }
  .inputCtn {
    display: grid;
    gap: 4px;
    margin-bottom: 20px;
  }
  .inputCtn p {
    font-size: small;
    margin: 5px;
    font-weight: bold;
  }
  .inputCtn input {
    padding: 8px 10px;
    outline: #a5a5a5 solid 2px;
    border: none;
    border-radius: 3px;
    font-size: 20px;
  }
  .inputCtn button {
    margin-top: 25px;
    padding: 10px;
    font-size: 20px;
  }

  @media screen and (max-width: 500px) {
    .form {
      width: 95%;
    }
  }
`;
