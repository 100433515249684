import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isSideNavbarOpen: false,
  activeTab: localStorage.getItem('sideNavIndex') || 0,
};

export const sideNavbarSlice = createSlice({
  name: "sideNavbar",
  initialState,
  reducers: {
    showSideNavbar: (state, action) => {
      state.isSideNavbarOpen = true;
    },
    hideSideNavbar: (state, action) => {
      state.isSideNavbarOpen = false;
    },
    setTab: (state, action) => {
      state.activeTab = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { showSideNavbar, hideSideNavbar, setTab } =
  sideNavbarSlice.actions;

export default sideNavbarSlice.reducer;
