import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import Button from "./ui/Button";
import { startLoading, stopLoading } from "../state/loadingSlice";
import { showError, showSuccess } from "../components/ui/ToastStyle";
import { API } from "../API";

const Settings = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const dispatch = useDispatch();

  function UpdatePassword(e) {
    e.preventDefault();
    dispatch(startLoading());

    API.post("/user/change-password", {
      currentPassword,
      newPassword,
    })
      .then((response) => {
        dispatch(stopLoading());
        if (response.data["type"] === 0) {
          showSuccess(response.data["message"]);
          localStorage.clear();
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          showError(response.data["message"]);
        }
      })
      .catch((error) => {
        dispatch(stopLoading());
      });
  }

  return (
    <Main>
      <div className="row">
        <h2>Change password</h2>
        <form className="changePasswordForm" onSubmit={UpdatePassword}>
          <div className="inputCtn">
            <p>Current password</p>
            <input
              type={"password"}
              value={currentPassword}
              required
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
          </div>
          <div className="inputCtn">
            <p>New password</p>
            <input
              type={"password"}
              value={newPassword}
              required
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className="inputCtn">
            <Button text={"Update"} />
          </div>
        </form>
      </div>
    </Main>
  );
};

export default Settings;

const Main = styled.div`
  padding: 50px;

  .row {
    border: 0;
    box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
    background: #fff;
    padding: 40px;
    border-radius: 15px;
    margin-bottom: 40px;
  }
  .row h2 {
    color: #333;
    margin-bottom: 15px;
  }
  .changePasswordForm {
    margin-top: 25px;
    width: 100%;
    max-width: 500px;
  }
  .inputCtn {
    display: grid;
    gap: 4px;
    margin-bottom: 20px;
  }
  .inputCtn p {
    font-size: small;
    margin: 5px;
    font-weight: bold;
  }
  .inputCtn input {
    padding: 8px 10px;
    outline: #a5a5a5 solid 2px;
    border: none;
    border-radius: 3px;
    font-size: 20px;
  }
  .inputCtn button {
    margin-top: 25px;
    padding: 10px;
    font-size: 20px;
  }
`;
