import React from "react";
import styled from "styled-components";
import { TailSpin } from "react-loader-spinner";
import { useSelector } from "react-redux";

const Loader = () => {
  const { isLoading } = useSelector((state) => state.loading);

  return (
    <Main style={{ display: isLoading ? "grid" : "none" }}>
      <TailSpin
        height="80"
        width="80"
        color="#0b4e88"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
        visible={isLoading}
      />
    </Main>
  );
};

export default Loader;

const Main = styled.div`
  width: 100vw;
  height: 100vh;
  place-items: center;
  padding: 50px;
  position: fixed;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  z-index: 999;
`;
