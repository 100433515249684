import React, { useEffect, useState } from "react";
import styled from "styled-components";
import AddVoucher from "./AddVoucher";
import { useDispatch } from "react-redux";
import { API } from "../API";
import { startLoading, stopLoading } from "../state/loadingSlice";
import Button from "./ui/Button";
import { showError } from "./ui/ToastStyle";
import Edit from "../assets/edit.png";

const Goals = () => {
  const [showAddVoucher, setShowAddVoucher] = useState(false);
  const [goalListData, setGoalListData] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [filter, setFilter] = useState(0);
  const [hasClaimed, setHasClaimed] = useState("");
  const [skip, setSkip] = useState(0);

  const dispatch = useDispatch();

  const Filters = ["All", "Claimed", 'Unclaimed'];

  function GetGoals() {
    dispatch(startLoading());
    API.get(`/snbl-goals?take=10&skip=${skip}&hasClaimed=${hasClaimed}`)
      .then((response) => {
        dispatch(stopLoading());
        if (response.data["type"] === 0) {
          setGoalListData(response.data["data"]);
        } else {
          showError(response.data["message"]);
        }
      })
      .catch((error) => {
        dispatch(stopLoading());
      });
  }

  function ApplyFilter(index) {
    if (filter == index) return;

    switch (index) {
      case 0:
        setHasClaimed("");
        break;
      case 1:
        setHasClaimed(true);
        break;
      case 2:
        setHasClaimed(false);
        break;
    }
    setGoalListData([]);
    setFilter(index);
  }

  function GetPagedData(type) {
    switch (type) {
      case "prev":
        if (skip == 0) return;
        setSkip(skip - 10);
        break;
      case "next":
        // if (skip == 0) return;
        setSkip(skip + 10);
        break;
    }
    setGoalListData([]);
  }

  useEffect(() => {
    goalListData.length === 0 && GetGoals();
  }, [showAddVoucher, filter, skip]);

  return (
    <Main>
      {showAddVoucher && (
        <AddVoucher
          showAddVoucher={setShowAddVoucher}
          reload={setGoalListData}
          userSnblRefId={selectedId}
        />
      )}

      <div className="ctn">
        <div className="filters">
          {Filters.map((item, index) => (
            <div key={index} onClick={() => ApplyFilter(index)}>
              <Button active={index == filter} text={item} />
            </div>
          ))}
        </div>
      </div>

      <div className="ctn">
        <table className="goalTable">
          <thead>
            <tr>
              <th>No.</th>
              <th>User</th>
              <th>Product Name</th>
              <th>Created On</th>
              <th>Offer Name</th>
              <th>Voucher Code</th>
              <th>Voucher Validity</th>
              <th>Voucher Status</th>
              <th>Goal Amount</th>
              <th>Reward Amount</th>
              <th>Voucher Amount</th>
              <th>Target Date</th>
            </tr>
          </thead>

          <tbody>
            {goalListData.map((item, index) => (
              <tr key={index}>
                <td className="alignCenter">{skip + index + 1}</td>
                <td>{item.userSnblRefId}</td>
                <td>{item.snblGoalName}</td>
                <td>{new Date(item.createdAt).toISOString().slice(0, 10)}</td>
                <td>{item.offerTitle}</td>
                {item.voucherCode ? (
                  <td className="voucherCode">
                    {item.voucherCode}
                    {item.hasClaimed == false && (
                      <img
                        onClick={() => {
                          setSelectedId(item.userSnblRefId);
                          setShowAddVoucher(true);
                        }}
                        src={Edit}
                      />
                    )}
                  </td>
                ) : (
                  <td
                    onClick={() => {
                      setSelectedId(item.userSnblRefId);
                      setShowAddVoucher(true);
                    }}
                  >
                    <Button text={"ADD"} />
                  </td>
                )}
                <td>
                  {item.voucherValidityDate &&
                    new Date(item.voucherValidityDate)
                      .toISOString()
                      .slice(0, 10)}
                </td>
                <td>{item.hasClaimed ? "Claimed" : "Unclaimed"}</td>
                <td>{item.targetAmount}</td>
                <td>{item.rewardAmount}</td>
                <td>{item.rewardAmount + item.targetAmount}</td>
                <td>{new Date(item.targetDate).toISOString().slice(0, 10)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="ctn">
        <div className="page">
          <div onClick={() => GetPagedData("prev")}>
            <Button active={skip == 0} text={"Prev"} />
          </div>
          <div onClick={() => GetPagedData("next")}>
            <Button text={"Next"} />
          </div>
        </div>
      </div>
    </Main>
  );
};

export default Goals;

const Main = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;

  .ctn {
    width: 95%;
    margin: auto;
    box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
    border-radius: 15px;
    overflow: hidden;
    padding: 10px;
    margin-bottom: 10px;
  }
  .goalTable {
    border-collapse: collapse;
    width: 100%;
  }
  .goalTable td,
  .goalTable th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  .goalTable th {
    background-color: #ddd;
  }
  .goalTable tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  .goalTable tr:hover {
    background-color: #ddd;
  }
  .alignCenter {
    text-align: center;
  }
  .voucherCode {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .voucherCode img {
    cursor: pointer;
  }
  .filters {
    display: flex;
    gap: 10px;
  }
  .page {
    display: flex;
    justify-content: space-between;
  }
`;
