import React from "react";
import styled from "styled-components";

const Footer = () => {
  return <Main>Copyright © 2023 Sav Technologies Limited</Main>;
};

export default Footer;

const Main = styled.div`
  background-color: #1a73e8;
  color: white;
  padding: 5px 10px;
  text-align: center;
  font-size: small;
`;
