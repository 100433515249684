import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import SavLogo from "../assets/logo.png";
import LogOut from "../assets/logout.png";
import CusomerService from "../assets/customer-service.png";
import Bars from "../assets/menu.png";
import { showSideNavbar } from "../state/sideNavbarSlice";

const Navbar = () => {
  const { isAuth } = useSelector((state) => state.auth);
  const { activeTab } = useSelector((state) => state.sideNavbar);

  const [pageTitle, setPageTitle] = useState("");

  const dispatch = useDispatch();

  function Logout(e) {
    e.preventDefault();
    localStorage.clear();
    window.location.reload();
  }

  function PageTitle() {
    switch (parseInt(activeTab)) {
      case 0:
        return "Dashboard";
      case 1:
        return "Sav Store";
      case 2:
        return "SNBL Transactions";
      case 3:
        return "Widget Docs";
      case 4:
        return "Settings";
    }
  }

  useEffect(() => {
    setPageTitle(PageTitle());
  }, [activeTab]);

  return (
    <Main>
      <div className="logoCtn">
        {isAuth && (
          <img
            onClick={() => dispatch(showSideNavbar())}
            src={Bars}
            className="bar"
          />
        )}
        <img src={SavLogo} className="savLogo" />
      </div>
      <h2 className="pageTitle">
        {isAuth ? pageTitle : "Sav Money SNBL Backoffice"}
      </h2>
      {isAuth ? (
        <form onSubmit={Logout}>
          <button className="logoutBtn">
            <img src={LogOut} />
            Log out
          </button>
        </form>
      ) : (
        <a className="support" href="mailto:support@sav.money">
          <button className="logoutBtn">
            <img src={CusomerService} />
            Need help ?
          </button>
        </a>
      )}
    </Main>
  );
};

export default Navbar;

const Main = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  letter-spacing: 1px;
  padding-left: 50px;
  padding-right: 50px;
  background-color: #f8f9fe;

  .savLogo {
    height: 35px;
  }
  .logoutBtn {
    color: rgba(0, 0, 0, 0.6);
    background-color: transparent;
    border: none;
    outline: none;
    font-weight: bold;
    font-size: medium;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .logoutBtn img {
    margin-right: 10px;
  }
  .logoCtn {
    display: flex;
    align-items: center;
  }
  .bar {
    height: 20px;
    margin-right: 20px;
    cursor: pointer;
  }
  .support {
    color: #666;
    text-decoration: none;
  }
`;
