import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const DashboardHome = () => {
  const { user } = useSelector((state) => state.auth);

  return (
    <Main>
      <div className="row">
        <h2>Welcome {user.name}!</h2>
        <img src={user.logo} className="merchantLogo" />
      </div>

      <div className="analyticsGrid">
        <div className="row">
          <h2>Total Goals</h2>
          <h4>11</h4>
        </div>
        <div className="row">
          <h2>Vouchers Issued</h2>
          <h4>10</h4>
        </div>
        <div className="row">
          <h2>Vouchers Claimed</h2>
          <h4>6</h4>
        </div>
        {/* <div className="row">
          <h2>Analytics D</h2>
          <h4>Loading data...</h4>
        </div> */}
      </div>
    </Main>
  );
};

export default DashboardHome;

const Main = styled.div`
  padding: 50px;

  .merchantLogo {
    height: 35px;
    position: absolute;
    right: 40px;
    top: 40px;
  }
  .analyticsGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;
    margin-top: 40px;
  }
  .row {
    border: 0;
    box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
    background: #fff;
    padding: 40px;
    border-radius: 15px;
    position: relative;
  }
  .row h2 {
    color: #333;
    margin-bottom: 15px;
  }
  .row h4 {
    color: #666;
    line-height: 1.5;
    font-weight: 500;
    text-align: justify;
  }
`;
