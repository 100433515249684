import axios from "axios";

let URL =
  process.env.REACT_APP_API_BASE_URL ||
  "https://snblbackoffice-api-staging2.sav.money";
  // "http://localhost:8020";

export const API = axios.create({
  baseURL: URL,
  headers: {
    Authorization: localStorage.getItem("token"),
  },
});
