import { toast } from "react-toastify";

const ToastStyle = {
  position: "top-center",
  autoClose: 2000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

export function showSuccess(message) {
  toast.success(message, ToastStyle);
}

export function showError(message) {
  toast.error(message, ToastStyle);
}

export function showWarning(message) {
  toast.warn(message, ToastStyle);
}
