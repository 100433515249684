import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: localStorage.getItem("token"),
  isAuth: localStorage.getItem("authState") ? true : false,
  user: JSON.parse(localStorage.getItem("authState"))
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoginState: (state, action) => {
      state.token = action.payload.token;
      state.isAuth = true;
    },
    setLogoutState: (state, action) => {
      state.token = "";
      state.isAuth = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoginState, setLogoutState } = authSlice.actions;

export default authSlice.reducer;
