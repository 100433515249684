import React from "react";
import styled from "styled-components";
import Button from "./ui/Button";
import Cta from "../assets/cta.png";
import { useSelector } from "react-redux";

const Widget = () => {
  const { user } = useSelector((state) => state.auth);

  return (
    <Main>
      <div className="row">
        <h2>Sav Money SNBL Plugin</h2>
        <h4>
          Sav Money SNBL Plugin is a javascript plugin which is compatible with
          most of the website platforms. It enables an e-commerce store to offer
          Sav SNBL functionality via a straight forward, 3 step integration
          process.
        </h4>
        <Button text={"▶️ Watch video"} />
      </div>

      <div className="row">
        <h2>Quick integration steps</h2>
        <h4>
          Sav Money SNBL Plugin can be integrated through a simple and easy
          three step process detailed below.
        </h4>
        <ul>
          <li>Include the Sav SNBL JS script.</li>
          <li>Add a Sav SNBL CTA on your relevant product pages.</li>
          <li>
            Call the Sav SNBL function by passing the necessary arguments.
          </li>
        </ul>
      </div>

      <div className="row">
        <h2>Step 1️⃣</h2>
        <h4>
          Add this script before ending the {"</body>"} tag, this will enable
          and initialize the Sav SNBL widget.
        </h4>
        <h4 className="codeSnippet">
          {"<!-- Sav SNBL Widget -->"}
          <br />
          {"<script type='text/javascript' id='savmsnblwidget'>"}
          <p>
            {`var savMoneyWidgetCode = '${user.savMoneyWidgetCode}';`}
          </p>
          <p>{"var savmScript = document.createElement('script');"}</p>
          <p>{"savmScript.type = 'text/javascript';"}</p>
          <p>{"savmScript.id = 'savmoneysnbl';"}</p>
          <p>{"savmScript.defer = true;"}</p>
          <p>
            {
              "savmScript.src = `https://snbl-staging.sav.money/widget/${savMoneyWidgetCode}`;"
            }
          </p>
          <p>{"savmTag = document.getElementsByTagName('script')[0];"}</p>
          <p>{"savmTag.parentNode.insertBefore(savmScript, savmTag);"}</p>
          {"</script>"}
        </h4>
      </div>

      <div className="row">
        <h2>Step 2️⃣</h2>
        <h4>Add a 'Save with Sav' SNBL CTA on your relevant product pages.</h4>
        <h4 className="description">
          <strong>Description : </strong>
          “Not ready to buy now? Start saving for it with Sav and get 10% off.
          Save now”
        </h4>
        <h4 className="description">
          <strong>Logo : </strong>
          https://snbl-staging.sav.money/assets/logo.png
        </h4>
        <img className="cta" src={Cta} />
      </div>

      <div className="row">
        <h2>Step 3️⃣</h2>
        <h4>
          Call this function on the onClick event for the Sav SNBL CTA. Please
          pass the basic product information as arguments to the function.
        </h4>
        <h4 className="description">
          <strong>productId : </strong>
          This is the product ID/ SKU which uniquely identifies the product
        </h4>
        <h4 className="description">
          <strong>productName : </strong>
          This is the name of the product, for example Addyson
        </h4>
        <h4 className="description">
          <strong>productImage : </strong>
          This is the default image of the product
        </h4>
        <h4 className="description">
          <strong>productPrice : </strong>
          This is the price for the product
        </h4>
        <h4 className="codeSnippet">
          onClick="openSavSNBLPopup(productId, productName, productImage,
          productPrice)"
        </h4>
      </div>

      <div className="row">
        <h2>IP whitelisting</h2>
        <h4>
          Before the SNBL plugin can be accessed by the merchant's app or
          website, IP whitelisting will have to be done. Please share your
          server IP address with us in order to provision the Sav SNBL plugin.
        </h4>
      </div>
    </Main>
  );
};

export default Widget;

const Main = styled.div`
  padding: 50px;

  .row {
    border: 0;
    box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
    background: #fff;
    padding: 40px;
    border-radius: 15px;
    margin-bottom: 40px;
  }
  .row h2 {
    color: #333;
    margin-bottom: 15px;
  }
  .row h4 {
    color: #666;
    line-height: 1.5;
    font-weight: 500;
    text-align: justify;
  }
  .row ul {
    margin-top: 15px;
    color: #666;
    line-height: 2;
    font-weight: 500;
    text-align: justify;
    margin-left: 20px;
  }
  .row button {
    padding: 10px 15px;
    font-size: 20px;
    margin-top: 40px;
  }
  .codeSnippet {
    background-color: #f4f5f7;
    padding: 15px 20px;
    border-radius: 0.375rem;
    font-family: monospace;
    margin-top: 20px;
    color: #333 !important;
    width: fit-content;
  }
  .codeSnippet p {
    font-family: monospace;
    padding-left: 20px;
  }
  .description {
    margin-top: 10px;
  }
  .cta {
    margin-top: 25px;
    width: 400px;
  }
`;
