import React from "react";
import { useSelector } from "react-redux";
import DashboardHome from "../components/DashboardHome";
import Goals from "../components/Goals";
import Login from "../components/Login";
import SavStore from "../components/SavStore";
import Settings from "../components/Settings";
import Widget from "../components/Widget";

const Dashboard = () => {
  const { isAuth } = useSelector((state) => state.auth);
  const { activeTab } = useSelector((state) => state.sideNavbar);

  return (
    <>
      {isAuth ? (
        <>
          {activeTab == 0 && <DashboardHome />}
          {activeTab == 1 && <SavStore />}
          {activeTab == 2 && <Goals />}
          {activeTab == 3 && <Widget />}
          {activeTab == 4 && <Settings />}
        </>
      ) : (
        <Login />
      )}
    </>
  );
};

export default Dashboard;
