import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { API } from "../API";
import { startLoading, stopLoading } from "../state/loadingSlice";
import Button from "./ui/Button";
import { showError, showSuccess } from "./ui/ToastStyle";

const AddVoucher = ({ showAddVoucher, reload, userSnblRefId }) => {
  const [voucherCode, setVoucherCode] = useState("");
  const [expiryDate, setExpiryDate] = useState(new Date());

  const dispatch = useDispatch();

  function Submit(e) {
    e.preventDefault();
    dispatch(startLoading());

    API.post("/snbl-goals/update-voucher-code", {
      userSnblRefId: userSnblRefId,
      voucherCode: voucherCode,
      voucherValidityDate: expiryDate,
    })
      .then((response) => {
        dispatch(stopLoading());
        if (response.data["type"] === 0) {
          showSuccess(response.data["message"]);
          showAddVoucher(false);
          reload([]);
        } else {
          showError(response.data["message"]);
        }
      })
      .catch((error) => {
        dispatch(stopLoading());
      });
  }

  return (
    <Main>
      <form className="container" onSubmit={Submit}>
        <p className="textCtn">
          User: <span>{userSnblRefId}</span>
        </p>
        <div className="inputCtn">
          <p>Voucher code</p>
          <input
            type={"text"}
            value={voucherCode}
            onChange={(e) => setVoucherCode(e.target.value)}
            required
          />
        </div>

        <div className="inputCtn">
          <p>Voucher validity date</p>
          <input
            type={"date"}
            value={expiryDate}
            onChange={(e) => setExpiryDate(e.target.value)}
            required
            min={new Date().toISOString().slice(0, 10)}
          />
        </div>

        <Button text={"Submit"} />
        <div className="cancelBtn" onClick={() => showAddVoucher(false)}>
          <Button text={"Cancel"} />
        </div>
      </form>
    </Main>
  );
};

export default AddVoucher;

const Main = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
  padding: 50px;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);

  .container {
    border: 3px solid black;
    border-radius: 10px;
    padding: 20px;
    min-width: 300px;
    background-color: white;
  }
  .inputCtn {
    display: grid;
    gap: 4px;
    margin-bottom: 20px;
  }
  .inputCtn p {
    font-size: small;
    margin: 5px;
  }
  .inputCtn input {
    padding: 8px 10px;
    outline: #a5a5a5 solid 2px;
    border: none;
    border-radius: 3px;
    font-size: 20px;
  }
  .container button {
    padding: 10px 15px;
    font-size: 20px;
    width: 100%;
    margin-top: 25px;
  }
  .cancelBtn button {
    background-color: tomato;
  }
  .cancelBtn button:hover {
    background-color: #333;
  }
  .textCtn {
    outline: #a5a5a5 solid 2px;
    border-radius: 5px;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    gap: 3px;
    margin-bottom: 15px;
    font-size: 12px;
  }
  .textCtn span {
    margin-top: 5px;
    font-size: 18px;
    font-weight: bold;
  }
`;
