import { BrowserRouter, Route, Routes } from "react-router-dom";
import Toast from "./components/Toast";
import Loader from "./components/Loader";
import Dashboard from "./pages/Dashboard";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import SideNavbar from "./components/SideNavbar";

function App() {
  return (
    <>
      <Toast />
      <Loader />
      <Navbar />
      <SideNavbar />
      <div
        style={{ minHeight: "calc(100vh - 125px)", backgroundColor: "#f8f9fe" }}
      >
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="*" element={<Dashboard />} />
          </Routes>
        </BrowserRouter>
      </div>
      <Footer />
    </>
  );
}

export default App;
